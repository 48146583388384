<template>
  <div style="height: 100%;">
    <v-card class="" :class="$vuetify.breakpoint.mdAndUp ? 'desktop_profile_card ml-4' : 'desktop_card'">
      <v-card-title>
        <label class="--v-primary-base text-left text-capitalize" :class="$vuetify.breakpoint.mdAndUp ? 'mx-6 profile-txt' : 'mr-4'">{{ $t(`label.myProfile`) }}</label>
        <v-btn class="icon-btn" icon v-if="!isPersonalProfileComplete" @click="openEditDialog()">
          <account-edit></account-edit>
        </v-btn>
      </v-card-title>
      <v-card class="profile-detail-card" elevation="0">
        <v-row no-gutters align="end" class="px-8 pa-4 hidden-md-and-up">
          <v-col md="3" lg="2">
            <label class="field-name">{{ $t(`field.email`) }}</label>
          </v-col>
          <v-col md="9" lg="10">
            <label>{{ memberInfo.email.value }}</label>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <!--desktop view username-->
        <v-row no-gutters align="end" class="hidden-sm-and-down pb-5">
          <v-col md="10" class="pb-2">
            <label class="field-name-desktop">{{ $t(`field.username`) }}</label>
          </v-col>
          <v-col md="5" class="pa-2" :class="[memberInfo.memberCode ? 'filled-label' : 'empty-label']">
            <label>{{ memberInfo.memberCode }}</label>
          </v-col>
        </v-row>
        <!--desktop view dob-->
        <v-row no-gutters align="end" class="hidden-sm-and-down pb-2">
          <v-col md="10" class="pb-2">
            <label class="field-name-desktop'">{{ $t(`field.dateOfBirth`) }}</label>
          </v-col>
          <v-col md="5" class="pa-2" :class="[memberInfo.dateOfBirth ? 'filled-label' : 'empty-label']">
            <label>{{ memberInfo.dateOfBirth | shortDate }}</label>
          </v-col>
        </v-row>
        <!--desktop view fullname-->
        <v-row no-gutters align="end" class="hidden-sm-and-down pb-2">
          <v-col md="10" class="pb-2">
            <label class="field-name-desktop">{{ $t(`field.fullname`) }}</label>
          </v-col>
          <v-col md="5" class="pa-2" :class="[memberInfo.name ? 'filled-label' : 'empty-label']">
            <label>{{ memberInfo.name }}</label>
          </v-col>
        </v-row>
        <!--desktop view currency-->
        <v-row no-gutters align="end" class="hidden-sm-and-down mb-4">
          <v-col md="10" class="pb-2">
            <label class="field-name-desktop">{{ $t(`field.currency`) }}</label>
          </v-col>
          <v-col md="5" class="pa-2" :class="[memberInfo.currency ? 'filled-label' : 'empty-label']">
            <label>{{ memberInfo.currency }}</label>
          </v-col>
        </v-row>
        <v-card-title class="hidden-sm-and-down px-0 pb-5">
          <label class="--v-primary-base text-left text-capitalize profile-txt">{{ $t(`label.contactDetails`) }}</label>
        </v-card-title>
        <!--desktop view email-->
        <v-row no-gutters align="end" class="hidden-sm-and-down pb-5">
          <v-col md="10" class="pb-2">
            <label class="field-name-desktop">{{ $t(`field.emailAddress`) }}</label>
          </v-col>
          <v-col md="5" class="pa-2" :class="[memberInfo.email.value ? 'filled-label' : 'empty-label']">
            <label>{{ memberInfo.email.value }}</label>
          </v-col>
        </v-row>
        <!--mobile view username-->
        <v-row no-gutters align="end" class="px-8 pa-4 hidden-md-and-up">
          <v-col md="3" lg="2">
            <label class="field-name">{{ $t(`field.username`) }}</label>
          </v-col>
          <v-col md="9" lg="10">
            <label>{{ memberInfo.memberCode }}</label>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <!--mobile view dob-->
        <v-row no-gutters align="end" class="px-8 pa-4 hidden-md-and-up">
          <v-col md="3" lg="2">
            <label class="field-name">{{ $t(`field.dateOfBirth`) }}</label>
          </v-col>
          <v-col md="9" lg="10">
            <label>{{ memberInfo.dateOfBirth | shortDate }}</label>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <!--desktop view mobile-->
        <v-row no-gutters align="end" class="hidden-sm-and-down" v-for="(n, k) in memberInfo.mobileList" :key="'desktop_' + k">
          <template v-if="k == 0">
            <v-col md="10" class="pb-2">
              <label class="field-name-desktop">{{ $t(`field.mobile_${k}`) }}</label>
            </v-col>
            <v-col md="5" class="pa-2 mb-4 phoneNo" :class="[n.value ? 'filled-label' : 'empty-label']">
              <label>{{ mobilePrefix }} {{ n.value | maskInfo }}</label>
              <div class="phoneVerificationStatus">
                <v-img :src="n.is_verified ? `/static/svg/personal-info-verified.svg` : `/static/svg/personal-info-warning.svg`" width="25" height="25" :contain="true"></v-img>
              </div>
              <label v-if="n.is_verified" class="verifiedTxt">{{ $t(`field.verified`) }}</label>
              <label v-else class="verifyOTPTxt" @click="openOTPDialog(k)">{{ $t(`field.verifyOTP`) }}</label>
            </v-col>
          </template>
          <template v-else-if="k > 0">
            <v-col md="10" class="pb-2">
              <label class="field-name-desktop">{{ $t(`field.mobile_${k}`) }}</label>
            </v-col>
            <v-col md="5" class="pa-2 phoneNo mb-4" :class="[n.value ? 'filled-label' : 'empty-label']">
              <label>{{ mobilePrefix }} {{ n.value | maskInfo }}</label>
              <div class="phoneVerificationStatus">
                <v-img :src="n.is_verified ? `/static/svg/personal-info-verified.svg` : `/static/svg/personal-info-warning.svg`" width="25" height="25" :contain="true"></v-img>
              </div>
              <label v-if="n.is_verified" class="verifiedTxt">{{ $t(`field.verified`) }}</label>
              <label v-else class="verifyOTPTxt" @click="openOTPDialog(k)">{{ $t(`field.verifyOTP`) }}</label>
            </v-col>
          </template>
        </v-row>
        <!--mobile view mobile-->
        <v-row no-gutters align="end" class="px-8 pa-4 hidden-md-and-up" v-for="(n, k) in memberInfo.mobileList" :key="'mobile_' + k">
          <template v-if="k == 0">
            <v-col md="1" lg="1" cols="0">
              <label class="field-name">{{ $t(`field.mobile_${k}`) }}</label>
            </v-col>
            <v-col md="9" lg="10" class="phoneNo">
              <label>{{ mobilePrefix }} {{ n.value | maskInfo }}</label>
              <div class="phoneVerificationStatus">
                <v-img :src="n.is_verified ? `/static/svg/personal-info-verified.svg` : `/static/svg/personal-info-warning.svg`" width="25" height="25" :contain="true"></v-img>
              </div>
              <label v-if="n.is_verified" class="verifiedTxt">{{ $t(`field.verified`) }}</label>
              <label v-else class="verifyOTPTxt" @click="openOTPDialog(k)">{{ $t(`field.verifyOTP`) }}</label>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </template>
          <template v-else-if="k > 0">
            <v-col md="1" lg="1" cols="0">
              <label class="field-name">{{ $t(`field.mobile_${k}`) }}</label>
            </v-col>
            <v-col md="9" lg="10" class="phoneNo">
              <label>{{ mobilePrefix }} {{ n.value | maskInfo }}</label>
              <div class="phoneVerificationStatus">
                <v-img :src="n.is_verified ? `/static/svg/personal-info-verified.svg` : `/static/svg/personal-info-warning.svg`" width="25" height="25" :contain="true"></v-img>
              </div>
              <label v-if="n.is_verified" class="verifiedTxt">{{ $t(`field.verified`) }}</label>
              <label v-else class="verifyOTPTxt" @click="openOTPDialog(k)">{{ $t(`field.verifyOTP`) }}</label>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </template>
        </v-row>
        <v-row v-if="memberInfo.mobileList.length < 3" no-gutters :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-8 pa-4 pt-0'" class="breakpoint row no-gutters align-end">
          <v-col cols="12" md="6" class="pt-0" :class="$vuetify.breakpoint.mdAndUp ? 'pb-2' : ''">
            <v-btn :class="$vuetify.breakpoint.mdAndUp ? 'add-mobile-btn' : 'add-mobile-btn-mobile'" @click="verifyPrimaryNumber()">
              <!--            <v-btn rounded color="primary" small @click="openAddNewNumberDialog(memberInfo.mobileList.length)">-->
              {{ memberInfo.mobileList.length == 1 ? $t(`field.addSecondaryPhone`) : $t(`field.addTertiaryPhone`) }}
            </v-btn>
          </v-col>
        </v-row>
        <!--mobile view fullname-->
        <v-row no-gutters align="end" class="px-8 pa-4 hidden-md-and-up">
          <v-col md="3" lg="2">
            <label class="field-name">{{ $t(`field.fullname`) }}</label>
          </v-col>
          <v-col md="9" lg="10">
            <label>{{ memberInfo.name }}</label>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <!--mobile view currency-->
        <v-row no-gutters align="end" class="px-8 pa-4 mb-4 hidden-md-and-up">
          <v-col md="3" lg="2">
            <label class="field-name">{{ $t(`field.currency`) }}</label>
          </v-col>
          <v-col md="9" lg="10">
            <label>{{ memberInfo.currency }}</label>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-card>
      <v-row v-if="$vuetify.breakpoint.lgAndUp" no-gutters align="center" class="px-8">
        <v-col cols="12" class="text-right">
          <p class="subtitle-2">
            {{ $t(`message.profileBtmMsg_full`) }}
            <!--            <span class="profile-live-chat" @click="openLiveChat()">{{ $t(`label.liveChat`) }}</span>-->
            <!--            {{ $t(`message.profileBtmMsg_2`) }}-->
          </p>
        </v-col>
      </v-row>
      <!-- EDIT DIALOG -->
      <app-dialog
        :dialogShow="editDialogShow"
        ref="personalEditDialog"
        :max-width="400"
        :title="$t(`label.editProfile`)"
        :isAuth="false"
        :header_title="'editProfile'"
        :closeAction="this.closeEditDialog"
        custom-class="edit-profile-dialog pa-6 pt-0"
      >
        <app-personal-edit :personalInfo="personalInfo" :closeAction="this.closeEditDialog"></app-personal-edit>
      </app-dialog>
    </v-card>
    <v-row v-if="$vuetify.breakpoint.mdAndDown" no-gutters align="center">
      <v-col cols="12" class="text-left mobile-subtitle ma-2">
        <!--        <p class="subtitle-2">-->
        {{ $t(`message.profileBtmMsg_full`) }}
        <!--        <span class="profile-live-chat" @click="openLiveChat()">{{ $t(`label.liveChat`) }}</span>-->
        <!--        {{ $t(`message.profileBtmMsg_2`) }}-->
        <!--        </p>-->
      </v-col>
    </v-row>

    <app-dialog :title="$t(`label.addNewNumber`)" :dialog-show="addNewNumberDialog" ref="addNewNumberOtpDialog" :closeAction="this.closeAddNumberDialog" :max-width="400">
      <v-form ref="addNumberForm">
        <template>
          <app-form-field
            :enabledLabel="true"
            v-model.trim="newContactNumber.mobile"
            :label="$t(`field.phoneNumber`)"
            :readonly="!newContactNumber.is_new_number"
            class="otpPhoneNo pb-2"
            :rules="validator.mobileNumberRules(defaultLanguage)"
            :prefix="mobilePrefix"
          ></app-form-field>
          <v-row no-gutters class="d-flex justify-center align-center">
            <app-button class="otpSubmitButton white--text" :action="this.AddNewSubContact" :title="$t(`button.submit`)"></app-button>
          </v-row>
        </template>
      </v-form>
    </app-dialog>

    <app-dialog :dialogShow="otpDialogShow" ref="personalOTPDialog" :closeAction="this.closeOTPDialog" :max-width="400" :title="$t(`label.verifyPhoneNo`)">
      <v-form ref="otpForm">
        <captcha ref="captchaCode" v-model.trim="captcha" :isOptional="true" :errorMsg="captchaErrorMsg" :rules="validator.captchaRules()"></captcha>
        <template>
          <app-form-field
            :enabledLabel="true"
            v-model.trim="this.memberInfo.mobileList[0].value"
            :label="$t(`field.firstNumber`)"
            :readonly="!newContactNumber.is_new_number"
            class="otpPhoneNo pb-2"
            :rules="validator.mobileNumberRules(defaultLanguage)"
            :prefix="mobilePrefix"
          ></app-form-field>
          <v-row no-gutters class="pb-5">
            <v-col cols="5">
              <div class="mr-2">
                <v-btn
                  height="auto"
                  :disabled="this.$store.state.member.smsVerificationSent.countDownTimer > 0"
                  @click="this.requestSmsVerificationCode"
                  color="primary"
                  depressed
                  class="otp-button theme-button font-weight-bold white--text mr-2"
                >
                  {{ this.$store.state.member.smsVerificationSent.countDownTimer > 0 ? formatSeconds(this.$store.state.member.smsVerificationSent.countDownTimer) : $t(`button.requestOtp`) }}
                </v-btn>
              </div>
            </v-col>
            <v-col>
              <app-form-field :enabledLabel="false" :placeholder="$t(`fieldHint.fillUpHere`)" :rules="validator.requiredRules()" v-model.trim="verificationCode" type="text"></app-form-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="d-flex justify-center align-center">
            <app-button class="otpSubmitButton black--text" :action="newContactNumber.is_new_number ? this.verifyAndAddNewContact : this.verifySmsCode" :title="$t(`button.submit`)"></app-button>
          </v-row>
        </template>
      </v-form>
    </app-dialog>
    <app-dialog :dialogShow="otpResultDialogShow" ref="otpResultDialog" :closeAction="this.closeOtpResultDialog" :max-width="350" :title="$t(`button.requestOtp`)">
      <!--      <label>{{ sendOTPAPIResult }}</label>-->
      <label>{{ smsVerificationResult ? 'Success' : 'Failed, try again' }}</label>
    </app-dialog>
  </div>
</template>
<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale, errorCodeHelper, formValidator } from '@/util'
import { SHARED } from '@/constants/constants'
import {
  MEMBER_DETAIL,
  MEMBER_UPDATE_DETAIL,
  MEMBER_RESET_UPDATE_DETAIL_STORE,
  MEMBER_SMS_REQUEST,
  MEMBER_SMS_VERIFY,
  MEMBER_REST_OTPRESULT,
  MEMBER_PHONE_VERIFICATION_STATUS,
  MEMBER_2ND_PHONE_VERIFICATION_STATUS,
  MEMBER_RESET_SMS_VERIFICATION,
  MEMBER_ADD_NEW_CONTACT,
  MEMBER_RESET_ADD_NEW_CONTACT
} from '@/store/member.module'
import { LOOKUP_COUNTRY, LOOKUP_PROVINCE } from '@/store/lookup.module'
import AccountEdit from '@/assets/svg/profile/edit.svg'
import AppPersonalEdit from '@/components/member/personalInfoEdit.vue'
import _ from 'lodash'
import Captcha from '@/components/Captcha.vue'

export default {
  name: 'myProfile',
  components: {
    Captcha,
    AccountEdit,
    AppPersonalEdit
  },
  data: () => ({
    defaultLanguage: SHARED.DEFAULT_LANGUAGE,
    newContactNumber: {
      mobile: '',
      is_new_number: false
    },
    is_add_new_number: false,
    addNewNumberDialog: false,
    phonePriority: 0,
    verificationCode: '',
    language: uiHelper.getLanguage(),
    validator: formValidator,
    dateOfBirthMaxDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().substr(0, 10),
    dateOfBirthMenu: false,
    shared: SHARED,
    isPersonalProfileComplete: false,
    tempMemberInfo: {
      email: '',
      name: ''
    },
    profileTab: [
      {
        key: 'account',
        link: ROUTE_NAME.PROFILE
      },
      {
        key: 'personal',
        link: ROUTE_NAME.PERSONAL
      }
    ],
    personalInfoObj: {
      gender: '',
      dateOfBirth: '',
      country: '',
      province: '',
      postcode: '',
      nationality: '',
      address: ''
    },
    editDialogShow: false,
    otpDialogShow: false,
    otpResultDialogShow: false,
    mobileNumberPrefixList: [
      {
        text: '+91',
        value: 'INR'
      },
      {
        text: '+880',
        value: 'BDT'
      },
      {
        text: '+977',
        value: 'NPR'
      }
    ],
    personalInfo: {
      memberCode: '',
      dateOfBirth: '',
      mobile: {
        value: ''
      },
      email: {},
      name: '',
      currency: '',
      secondaryMobile: {
        value: ''
      },
      fullName: ''
    },
    sendOTPAPIResult: '',
    captcha: '',
    captchaErrorMsg: undefined
  }),
  created() {
    this.getMemberDetail()
    this.getCountry()
  },
  computed: {
    smsOTPErrorMsg() {
      return this.$store.state.member.smsVerificationSent.code
    },
    smsCountdownTimer() {
      return this.$store.state.member.smsVerificationSent.countDownTimer.toString()
    },
    smsVerificationResult() {
      return this.$store.state.member.smsVerificationResult.loaded
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    updateDetailResponseComplete() {
      return this.$store.state.member.updateDetailResponse.complete
    },
    addNewContactNumberResponse() {
      return this.$store.state.member.addNewNumberContact.complete
    },
    country() {
      return this.$store.state.lookup.country
    },
    province() {
      return this.$store.state.lookup.province
    },
    mobilePrefix() {
      let self = this
      let prefix = _.find(this.mobileNumberPrefixList, function(p) {
        return p.value == self.memberInfo.currency
      })
      if (prefix) return prefix.text
      else return ''
    },
    cPhonePriority() {
      let arrIndex = this.phonePriority
      const mobileList = this.memberInfo.mobileList
      if (Array.isArray(mobileList) && mobileList.length > arrIndex) {
        this.newContactNumber.mobile = mobileList[arrIndex].value
        return mobileList[arrIndex].value
      }
    }
  },
  watch: {
    smsVerificationResult() {
      let response = this.$store.state.member.smsVerificationResult
      //show alert on repsonse //loaded =true : back from server with response
      if (response.loaded) {
        if (response.success) {
          //if reponse=phone verified show alert and close while otp window
          this.$parent.openAlert(true, 'Phone verified successfully')
          this.otpDialogShow = false

          if (this.is_add_new_number) {
            this.newContactNumber.is_new_number = true
            this.newContactNumber.mobile = ''
            this.addNewNumberDialog = true
            this.is_add_new_number = false
          }
          let index = this.phonePriority
          this.$store.dispatch(`${MEMBER_PHONE_VERIFICATION_STATUS}`, index)
          // if (this.phonePriority == 1) {
          //   this.$store.dispatch(`${MEMBER_PHONE_VERIFICATION_STATUS}`)
          // } else if (this.phonePriority == 2) {
          //   this.$store.dispatch(`${MEMBER_2ND_PHONE_VERIFICATION_STATUS}`)
          // }
        } else {
          if (response.code == -1) {
            this.$parent.openAlert(response.success, locale.getMessage('fieldErrorMessage.invalidCaptchaCode'))
          } else if (response.code == -3) {
            this.$parent.openAlert(response.success, locale.getMessage('fieldErrorMessage.tooManyRequest'))
          } else if (response.code == 0) {
            this.$parent.openAlert(response.success, locale.getMessage('message.otpFailVerification'))
          } else {
            this.$parent.openAlert(true, `Failed to verify (${response.message}). please try again`)
          }
        }
        this.$store.dispatch(`${MEMBER_REST_OTPRESULT}`)
      }
    },
    smsOTPErrorMsg() {
      const responseCode = this.$store.state.member.smsVerificationSent.code
      if (responseCode != '0') {
        if (responseCode == -1) {
          this.$parent.openAlert(false, locale.getMessage('fieldErrorMessage.invalidCaptchaCode'))
        } else if (responseCode == -3) {
          this.$parent.openAlert(false, locale.getMessage('fieldErrorMessage.tooManyRequest'))
        } else {
          this.errorDialog(responseCode)
        }
      }
    },
    memberInfo() {
      let info = this.$store.state.member.info
      this.personalInfo = {
        memberCode: info.memberCode,
        dateOfBirth: info.dateOfBirth,
        mobile: info.mobile,
        name: info.name,
        currency: info.currency,
        email: info.email,
        secondaryMobile: info.secondaryMobile,
        fullName: info.name
      }

      if (this.personalInfo.dateOfBirth && this.personalInfo.name && this.personalInfo.email.value) {
        this.isPersonalProfileComplete = true
      }
    },
    dateOfBirthMenu(val) {
      val && setTimeout(() => (this.$refs.dateOfBirthPicker.activePicker = 'YEAR'))
    },
    updateDetailResponseComplete() {
      let response = this.$store.state.member.updateDetailResponse
      if (response.complete) {
        this.updateDetailResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_UPDATE_DETAIL_STORE}`)
      }
    },
    addNewContactNumberResponse() {
      if (this.$store.state.member.addNewNumberContact.complete) {
        if (this.$store.state.member.addNewNumberContact.success) {
          this.$parent.openAlert(true, 'Phone verified successfully')
          this.otpDialogShow = false
          this.addNewNumberDialog = false
          let index = this.phonePriority
          this.getMemberDetail()
          this.$store.dispatch(`${MEMBER_PHONE_VERIFICATION_STATUS}`, index)
        } else {
          this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(this.$store.state.member.addNewNumberContact.code))
        }
        this.$store.dispatch(`${MEMBER_RESET_ADD_NEW_CONTACT}`)
        this.$store.dispatch(`${MEMBER_REST_OTPRESULT}`)
        this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      }
    }
  },

  methods: {
    verifyPrimaryNumber() {
      this.phonePriority = 0
      this.newContactNumber.is_new_number = false
      this.newContactNumber.mobile = this.memberInfo.mobileList[0].value
      this.otpDialogShow = true
      this.is_add_new_number = true
    },
    closeAddNumberDialog() {
      this.addNewNumberDialog = false
    },
    openAddNewNumberDialog(phoneNumCount) {
      this.verificationCode = ''
      this.phonePriority = phoneNumCount
      this.newContactNumber.is_new_number = true
      this.newContactNumber.mobile = ''
      this.otpDialogShow = true
    },
    errorDialog(errorCode) {
      if (errorCode == 0) {
        return
      }
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.system`)
      dialog.dialogXButton = null
      let msg = errorCodeHelper.getErrorCodeDesc(errorCode)
      if (errorCode == '3.32.6') {
        msg += '. ' + locale.getMessage('label.contactCsChangePhoneNo')
      }
      dialog.message.push(msg)
      dialog.button.push({
        title: locale.getMessage(`label.close`),
        action: () => this.closePageDialog()
      })
      this.openPageDialog(dialog)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      this.$eventHub.$emit('close-page-dialog', true)
    },
    closeOtpResultDialog() {
      this.otpResultDialogShow = false
    },
    formatSeconds(secs) {
      const pad = n => (n < 10 ? `0${n}` : n)

      const h = Math.floor(secs / 3600)
      const m = Math.floor(secs / 60) - h * 60
      const s = Math.floor(secs - h * 3600 - m * 60)

      return `${pad(m)}:${pad(s)}`
    },
    async AddNewSubContact() {
      if (this.$refs.addNumberForm.validate()) {
        let filter = {
          verification_code: '',
          mobileNumber: this.newContactNumber.mobile,
          required_only_primary_verified: true
        }

        this.$store.dispatch(`${MEMBER_ADD_NEW_CONTACT}`, {
          filter
        })
      }
    },
    async verifyAndAddNewContact() {
      if (this.$refs.otpForm.validate()) {
        let filter = {
          verification_code: this.verificationCode,
          mobileNumber: this.newContactNumber.mobile,
          required_only_primary_verified: true
        }

        this.$store.dispatch(`${MEMBER_ADD_NEW_CONTACT}`, {
          filter
        })
      }
    },
    async verifySmsCode() {
      // if (this.$refs.otpForm.validate()) {
      let verifyPhoneNumber = this.memberInfo.mobileList[0].value
      {
        let registerObj = {
          verificationCode: this.verificationCode,
          mobileNumber: verifyPhoneNumber,
          captchaId: this.$refs.captchaCode.$refs.code.id,
          captchaCode: this.captcha
        }
        this.$store.dispatch(`${MEMBER_SMS_VERIFY}`, {
          registerObj
        })
      }
    },
    requestSmsVerificationCode() {
      this.$store.state.member.smsVerificationSent.success = false
      let verifyPhoneNumber = this.memberInfo.mobileList[0].value
      //if (this.$refs.registerForm2.validate()) {
      if (this.captcha != '') {
        let registerObj = {}

        registerObj.domain = uiHelper.getHostname()
        registerObj.language = uiHelper.getLanguage()
        registerObj.currency = this.personalInfo.currency
        registerObj.memberCode = this.personalInfo.memberCode
        registerObj.mobileNumber = verifyPhoneNumber
        registerObj.request_otp = true
        registerObj.captchaId = this.$refs.captchaCode.$refs.code.id
        registerObj.captchaCode = this.captcha

        this.$store.dispatch(`${MEMBER_SMS_REQUEST}`, {
          registerObj
        })
      }
      //}
    },
    openOTPDialog(_phonePriority) {
      this.verificationCode = ''
      this.phonePriority = _phonePriority
      let arrIndex = this.phonePriority
      const mobileList = this.memberInfo.mobileList
      this.newContactNumber.is_new_number = false
      if (Array.isArray(mobileList) && mobileList.length > arrIndex) {
        this.newContactNumber.mobile = mobileList[arrIndex].value
      }
      this.otpDialogShow = true
    },
    closeOTPDialog() {
      this.otpDialogShow = false
      this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
    },
    openEditDialog() {
      this.editDialogShow = true
    },
    closeEditDialog() {
      this.editDialogShow = false
    },
    getMemberDetail() {
      this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    getCountry() {
      let countryLookupObj = {
        language: uiHelper.getCookie('language') + '-cn'
      }
      this.$store.dispatch(`${LOOKUP_COUNTRY}`, { countryLookupObj })
    },
    getCountryProvince(data) {
      let provinceLookupObj = {
        language: uiHelper.getCookie('language') + '-cn',
        countryCode: data
      }
      this.$store.dispatch(`${LOOKUP_PROVINCE}`, { provinceLookupObj })
    },
    countryDisplayText(data) {
      return data.displayText
    },
    provinceDisplayText(data) {
      return data.displayText
    },
    updatePersonalInfo() {
      if (this.$refs.profileForm.validate()) {
        let memberObj = this.personalInfoObj

        let countryLocalname = this.country.find(x => x.value == memberObj.country).displayText
        let nationalityLocalname = this.country.find(x => x.value == memberObj.nationality).displayText
        memberObj.country = countryLocalname
        memberObj.nationality = nationalityLocalname
        this.$store.dispatch(`${MEMBER_UPDATE_DETAIL}`, { memberObj })
      }
    },
    updateDetailResponseCompleteDialog(response) {
      if (response.code != 0 && !response.success) {
        this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
      } else {
        this.$parent.openAlert(true, locale.getMessage(`message.memberPersonalDetailUpdated`))
        this.$store.dispatch(`${MEMBER_DETAIL}`)
      }
    },
    openLiveChat() {
      this.$parent.$parent.openLiveChat()
    }
  }
}
</script>
<style lang="scss">
.desktop_profile_card {
  border-radius: 12px !important;
  min-height: 600px !important;
  font-family: 'Roboto', sans-serif;
  height: 100%;

  .profile-txt {
    font-weight: bold;
    font-size: 18px;
  }
}
.filled-label {
  border: 1px solid #d3d3d3;
  background-color: #f3f5f6;
  color: #6c6c6c;
  font-weight: bold;
  border-radius: 12px;
  height: 40px;
}

.add-mobile-btn {
  background-color: #ffce01 !important;
  height: 40px !important;
  font-family: 'Roboto', sans-serif !important;
  border-radius: 12px !important;
  font-size: 14px;
  color: #000 !important;
  width: 60%;
}

.add-mobile-btn-mobile {
  background-color: #3a3a3a !important;
  font-weight: 500;
  min-width: 50px !important;
  height: 28px !important;
  border-radius: 28px !important;
  font-size: 12px !important;
  color: white !important;
}

.empty-label {
  border: 1px solid #d3d3d3;
  background-color: #fff;
  color: #959595;
  font-weight: bold;
  border-radius: 12px;
  height: 40px;
}

.field-name-desktop {
  color: #000;
}
.mobile-subtitle {
  font-size: 0.8rem !important;
  font-weight: normal;
}

.phoneNo {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.phoneVerificationStatus {
  display: flex;
  padding-left: 2em;
  padding-right: 0.5em;
}

.verifyOTPTxt {
  color: #f88717 !important;
  text-decoration: underline;
  cursor: pointer;
}

.verifiedTxt {
  color: #27ae60 !important;
}

.profile-live-chat {
  color: #f88717 !important;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 959px) {
  .profile-page {
    hr {
      display: none;
    }

    .v-card {
      box-shadow: none;
    }
  }
  .page-title-container {
    display: none;
  }
  .breakpoint {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
}

@media (max-width: 1150px) and (min-width: 959px) {
  .add-mobile-btn {
    background-color: #ffce01 !important;
    height: 40px !important;
    font-family: 'Roboto', sans-serif !important;
    border-radius: 12px !important;
    font-size: 12px !important;
    color: #000 !important;
    width: 60%;
  }
}

.edit-profile-dialog {
  max-height: none !important;
  overflow-y: hidden !important;
}
</style>
